<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>后台管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 10px 0px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input size="mini" placeholder="请输入昵称" v-model="queryInfo.name" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-select size="mini" v-model="queryInfo.enable" clearable placeholder="请选择用户状态">
            <el-option key="1" label="冻结" value="false"></el-option>
            <el-option key="2" label="启动" value="true"></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getUserList">搜索</el-button>
        </el-col>
      </el-row>
      <el-table size="mini" :data="artilceList" border style="margin-top: 10px">
        <!-- 添加展开列 -->
        <!-- <el-table-column type="expand"></el-table-column> -->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="昵称" prop="name"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="用户状态" prop="enable">
          <template slot-scope="scope">
            <el-switch
              inactive-color="#ff4949"
              active-color="#13ce66"
              active-text="激活"
              inactive-text="冻结"
              v-model="scope.row.enable"
              @change="changeStatus(scope.row.id,scope.row.enable)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="150px" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="190px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              icon="el-icon-position"
              @click="showMail(scope.row)"
            >发邮件</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="remove(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <!-- 邮件对话框 -->
    <el-dialog title="添加角色" :visible.sync="addMailVisible" width="30%" @close="addMailClosed">
      <el-form :model="mail" ref="addMailForm" size="mini" label-width="100px">
        <el-form-item label="收件人" prop="receiver">
          <el-input v-model="mail.receiver" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="mail.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="mail.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMailVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendMail()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "articleManage",
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      artilceList: [],
      tagList: [],
      mail: {},
      addMailVisible: false
    };
  },
  created() {
    this.getUserList();
    this.getArticleTag();
  },
  methods: {
    // 用户列表
    async getUserList() {
      const { data: res } = await this.$api.getUserList(this.queryInfo);
      this.artilceList = res.data.list;
      this.total = res.data.total;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList();
    },
    handleCurrentChange(current) {
      this.queryInfo.pageNum = current;
      this.getUserList();
    },
    // 改变用户状态
    async changeStatus(id, enable) {
      const { data: res } = await this.$api.editUser(id, { enable: enable });
      this.getUserList();
    },
    // 删除用户
    async remove(id) {
      const result = await this.$confirm("是否删除该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteUser(id);
      this.getUserList();
    },
    // 获取标签
    async getArticleTag() {
      const { data: res } = await this.$api.getArticleTag();
      this.tagList = res.data;
    },
    showMail(row){
        this.mail.receiver = row.email
        this.addMailVisible = true;
    },
    async sendMail() {
         const { data: res } = await this.$api.sendMail(this.mail);
         this.addMailVisible = false;
    },
    addMailClosed(){
        this.$refs.addMailForm.resetFields();
    }
  }
};
</script>